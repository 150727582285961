import React from 'react';
import { withRouter } from 'react-router-dom';
import BaseScreen from 'screens/BaseScreen';

class HomeScreen extends BaseScreen {
  constructor(props) {
    super(props);
    this.state = { title: 'About', breadcrumb: [] };
  }

  renderContent = () => {
    return (
      <div style={{ padding: 20 }}>
        <div class="justify-content-center">
          <div class="text-center center-block">
            <br />
            <b>
              This is a fan WEB (<a href="http://www.cocbases.top">http://www.cocbases.top</a>)( This Website is not affiliated with, endorsed, sponsored, or
              specifically approved by Supercell. Supercell is not responsible for the operation or content of this application. Use of the trademarks and other
              intellectual property of Supercell is subject to Supercell’s Fan Kit Agreement and
              <br />
              <a href="http://supercell.com/en/fan-content-policy/">FAN CONTENT POLICY</a>. For more information about Supercell, please visit their website at
              www.supercell.net.
              <br />
              <br />
              <h5>If you have any questions or suggestions about us, do not hesitate to contact me at:</h5>
              <h4>
                <a href="mailto:contact@cocbases.top">contact@cocbases.top</a>
              </h4>
            </b>
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(HomeScreen);

import { ButtonBase } from '@material-ui/core';
import React from 'react';
import './FilterView.scss';

export default ({ hall = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], onClickFilterItem = () => () => {}, isBuilderHall = false }) => {
  return (
    <div className={'FilterView body'} style={{ padding: 20, justifyContent: 'center', display: 'flex' }}>
      {hall.map((e, i) => (
        <ButtonBase key={i} className={'btnHall'} onClick={onClickFilterItem(e)}>
          <img src={`/images/${isBuilderHall ? 'builder/Builder_Hall' : 'hall/Town_Hall'}${e}.png`} style={{ width: 20, height: 20 }} />
          {'TH' + e}
        </ButtonBase>
      ))}
    </div>
  );
};

import React from 'react';
import { withRouter } from 'react-router-dom';
import BaseScreen from 'screens/BaseScreen';

class HomeScreen extends BaseScreen {
  constructor(props) {
    super(props);
    this.state = { title: 'Contact', breadcrumb: [] };
  }

  renderContent = () => {
    return (
      <div style={{ padding: 20 }}>
        <div class="justify-content-center">
          <div class="text-center center-block">
            <img class="h_150px" src="/images/webs/coc-icon-h.png" />
            <br />
            <b>
              We are here for you. Please get in touch with our Support Helpdesk today. Our team is ready to assist with your queries 24/7. We will respond to
              all your queries in a timely manner. Our esteem users can contact us through email. Why not contact us today?
              <br />
              <h5>
                Forward all your enquires do not hesitate to contact me at
                <a href="mailto:contact@cocbases.top">contact@cocbases.top</a>
              </h5>
            </b>
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(HomeScreen);

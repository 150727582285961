import { Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import Fetch from 'common/helpers/Fetch';
import ApiConst from 'consts/ApiConst';
import AppConst from 'consts/AppConst';
import { withRouter } from 'react-router-dom';
import Utils from 'utils/Utils';
import { ListBaseLinkScreen } from '../ListBaseLinkScreen';

export const TypographyTitle = withStyles(() => createStyles({ root: { fontWeight: '700', fontSize: 14, color: '#25282B' } }))(Typography);

class ListBaseLayoutScreen extends ListBaseLinkScreen {
  constructor(props) {
    super(props);
    this.type = 2;
    this.path = 'base-layout-town-hall';
    this.hallList = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  }

  getData = async () => {
    const itemsPerPage = 20;
    this.setState({
      title: 'Base hall ' + this.state.hall,
      breadcrumb: [{ to: this.props.location.pathname, title: 'Base hall ' + this.state.hall }]
    });
    const res = await Fetch.get(ApiConst.USER_BASE.GET_LIST, { page: this.state.page, hall: this.state.hall, itemsPerPage });
    if (res.status === 200) {
      Utils.data = res.data.data?.map((e) => {
        const tags = [...AppConst.TAGS, 'TH' + e.hall];
        return { ...e, tags };
      });
      this.setState({ data: Utils.data, hasNext: Utils.data.length >= itemsPerPage });
    }
  };
}

export default withRouter(ListBaseLayoutScreen);

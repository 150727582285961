import json5 from 'json5';
import Configs from 'utils/Configs';

const Funcs = {
  log: (() => {
    if (Configs.mode === 'prod') return () => {};
    return Function.prototype.bind.call(console.log, console, '## LOG: ');
  })(),

  jsonEscape: (str) => {
    return str.replace(/\n/g, '\\\\n').replace(/\r/g, '\\\\r').replace(/\t/g, '\\\\t');
  },

  getBaseUrl: (url) => {
    let arr = url.split('/');
    let fileName = arr[arr.length - 1];
    return fileName;
  },

  getFileExt: (path) => {
    let arr = path.split('.');
    if (arr.length < 2) return '';
    return arr[arr.length - 1];
  },

  formatNumber: (num = 0, char = ',') => {
    if (!num) return '0';
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${char}`);
  },

  checkStringEmpty: (string) => {
    return /^ *$/.test(string);
  },

  delay: (time) => {
    var promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          resolve();
        } catch (err) {
          reject({
            error: err
          });
        }
      }, time);
    });
    return promise;
  },

  random: (from, to) => {
    // return Math.floor(Math.random() * (to + add) + from);
    return Math.floor(Math.random() * (to - from + 1)) + from;
  },

  swapArray: (arr = []) => {
    for (let i = 0; i < arr.length - 1; i += 1) {
      const randomIndex = Funcs.random(0, arr.length - 1);
      let tmp = arr[i];
      arr[i] = arr[randomIndex];
      arr[randomIndex] = tmp;
    }
    return arr;
  },

  compareObject: (objA, objB) => {
    if ((objA === null && objB != null) || (objB === null && objA != null)) return false;
    var aProps = Object.getOwnPropertyNames(objA);
    var bProps = Object.getOwnPropertyNames(objB);
    if (aProps.length !== bProps.length) {
      return false;
    }
    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];
      if (objA[propName] !== objB[propName]) {
        return false;
      }
    }
    return true;
  },

  convertSecondToTime: (totalSeconds) => {
    var timeStr = '00:00';
    var second = totalSeconds % 60;
    var min = Math.floor(totalSeconds / 60);
    if (second < 10) second = '0' + second;
    if (min < 10) min = '0' + min;
    timeStr = min + ':' + second;
    return timeStr;
  },

  md5: (str) => {},

  jsonParse(str) {
    try {
      return json5.parse(str);
    } catch (err) {
      Funcs.log('ERROR', err);
    }
    return null;
  }
};

export default Funcs;

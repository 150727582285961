import Button from 'common/components/views/Button';
import React from 'react';
import { GrClose } from 'react-icons/gr';
import Modal from 'react-modal';
import styles from './styles.module.css';

export default class ModalMcpeDetail extends React.Component {
  static instance = null;

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    ModalMcpeDetail.instance = this;
  }

  static show = async (data) => {
    ModalMcpeDetail.instance?.setState({
      visible: true,
      data
    });
  };

  static hide = () => {
    ModalMcpeDetail.instance?.setState({
      visible: false
    });
  };

  render() {
    const { data } = this.state;
    return (
      <Modal
        onRequestClose={ModalMcpeDetail.hide}
        ariaHideApp={false}
        isOpen={this.state.visible}
        style={{
          overlay: { backgroundColor: 'rgba(0,0,0, 0.5)' },
          content: { maxWidth: 1132, maxHeight: 710, margin: 'auto', overflow: 'hidden', padding: 0 }
        }}>
        <div className={styles.header}>
          <div />
          <div>{data?.title}</div>
          <Button onClick={ModalMcpeDetail.hide}>
            <GrClose />
          </Button>
        </div>
        <div className={styles.contentContainer}>
          <div dangerouslySetInnerHTML={{ __html: data?.content }} />
        </div>
      </Modal>
    );
  }
}

import React from 'react';
import { withRouter } from 'react-router-dom';
import BaseScreen from 'screens/BaseScreen';

class PrivacyScreen extends BaseScreen {
  constructor(props) {
    super(props);
    this.state = { title: 'Privacy Policy', breadcrumb: [] };
  }

  renderContent = () => {
    return (
      <div style={{ padding: 20 }}>
        <div class="col-lg-12">
          <h2 class="mb-3 line-head">cocbases.top Terms of Service and Privacy Policy</h2>
          <div>
            <h4>1. Terms</h4>
            <p>
              By accessing the website at http://www.cocbases.top, you are agreeing to be bound by these terms of service, all applicable laws and regulations,
              and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited
              from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
            </p>
            <h4>2. Use License</h4>
            <p>
              Permission is granted to temporarily download one copy of the materials (information or software) on cocbases.top website for personal,
              non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
            </p>
            <ul>
              <li>modify or copy the materials;</li>
              <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
              <li>attempt to decompile or reverse engineer any software contained on cocbases.topwebsite;</li>
              <li>remove any copyright or other proprietary notations from the materials; or</li>
              <li>transfer the materials to another person or mirror the materials on any other server.</li>
            </ul>
            <p>
              This license shall automatically terminate if you violate any of these restrictions and may be terminated by cocbases.top at any time. Upon
              terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession
              whether in electronic or printed format.
            </p>
            <h4>3. Disclaimer</h4>
            <p>
              The materials on cocbases.top website are provided on an as is basis. cocbases.top makes no warranties, expressed or implied, and hereby disclaims
              and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular
              purpose, or non-infringement of intellectual property or other violation of rights.
            </p>
            <p>
              Further, cocbases.top does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the
              materials on its website or otherwise relating to such materials or on any sites linked to this site.
            </p>
            <h4>4. Limitations</h4>
            <p>
              In no event shall cocbases.top or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or
              due to business interruption) arising out of the use or inability to use the materials on cocbases.top website, even if cocbases.top or a
              cocbases.top authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not
              allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to
              you.
            </p>
            <h4>5. Accuracy of materials</h4>
            <p>
              The materials appearing on cocbases.top website could include technical, typographical, or photographic errors. cocbases.top does not warrant that
              any of the materials on its website are accurate, complete or current. cocbases.top may make changes to the materials contained on its website at
              any time without notice. However cocbases.top does not make any commitment to update the materials.
            </p>
            <h4>6. Links</h4>
            <p>
              cocbases.top has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The
              inclusion of any link does not imply endorsement by cocbases.top of the site. Use of any such linked website is at the user own risk.
            </p>
            <h4>7. Modifications</h4>
            <p>
              cocbases.top may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by
              the then current version of these terms of service.
            </p>
            <h4>8. Governing Law</h4>
            <p>
              These terms and conditions are governed by and construed in accordance with the laws of France and you irrevocably submit to the exclusive
              jurisdiction of the courts in that State or location.
            </p>
            <h2>Privacy Policy</h2>
            <p>Your privacy is important to us.</p>
            <p>
              It is cocbases.top policy to respect your privacy regarding any information we may collect while operating our website. Accordingly, we have
              developed this privacy policy in order for you to understand how we collect, use, communicate, disclose and otherwise make use of personal
              information. We have outlined our privacy policy below.
            </p>
            <p>
              We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.
            </p>
            <p>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.</p>
            <p>
              We will collect and use personal information solely for fulfilling those purposes specified by us and for other ancillary purposes, unless we
              obtain the consent of the individual concerned or as required by law.
            </p>
            <p>
              Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate,
              complete, and up-to-date.
            </p>
            <p>
              We will protect personal information by using reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure,
              copying, use or modification.
            </p>
            <p>We will make readily available to customers information about our policies and practices relating to the management of personal information.</p>
            <p>We will only retain personal information for as long as necessary for the fulfilment of those purposes.</p>
            <p>
              We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal
              information is protected and maintained. Clash of Stats may change this privacy policy from time to time at cocbases.top sole discretion.
            </p>
          </div>
          <p>cocbases.top built the web/app as a Free. This SERVICE is provided by cocbases.top at no cost and is intended for use as is.</p>
          <p>
            This page is used to inform website visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone
            decided to use my Service.
          </p>
          <p>
            If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that I
            collect is used for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy
            Policy.
          </p>
          <p>
            The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at web/app unless otherwise defined
            in this Privacy Policy.
          </p>
          <p>
            <strong>Information Collection and Use</strong>
          </p>
          <p>
            For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information, including but
            not limited to External Storage, User Location. The information that I request is retained on your device and is not collected by me in any way
          </p>
          <p>The app does use third party services that may collect information used to identify you.</p>
          <div>
            <p>Link to privacy policy of third party service providers used by the app</p>
            <ul>
              <li>
                <a href="https://www.google.com/policies/privacy/">Google Play Services</a>
              </li>
            </ul>
          </div>
          <p>
            <strong>Log Data</strong>
          </p>
          <p>
            I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and information (through third party
            products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name,
            operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other
            statistics.
          </p>
          <p>
            <strong>Cookies</strong>
          </p>
          <p>
            Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website
            that you visit and are stored on your device internal memory.
          </p>
          <p>
            This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collection
            information and to improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your
            device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
          </p>
          <p>
            <strong>Service Providers</strong>
          </p>
          <p>I may employ third-party companies and individuals due to the following reasons:</p>
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p>
            I want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks
            assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
          </p>
          <p>
            <strong>Security</strong>
          </p>
          <p>
            I value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But
            remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its
            absolute security.
          </p>
          <p>
            <strong>Links to Other Sites</strong>
          </p>
          <p>
            This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites
            are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no
            responsibility for the content, privacy policies, or practices of any third-party sites or services.
          </p>
          <p>
            <strong>Children’s Privacy</strong>
          </p>
          <p>
            These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In
            the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent
            or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do necessary
            actions.
          </p>
          <p>
            <strong>Changes to This Privacy Policy</strong>
          </p>
          <p>
            I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any
            changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
          </p>
          <p>
            <strong>Contact Us</strong>
          </p>
          <p>
            If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at
            <a href="mailto:contact@cocbases.top">contact@cocbases.top</a>.
          </p>
        </div>
      </div>
    );
  };
}

export default withRouter(PrivacyScreen);

import ModalLoading from 'common/components/modals/ModalLoading';
import Button from 'common/components/views/Button';
import Fetch from 'common/helpers/Fetch';
import ApiConst from 'consts/ApiConst';
import UrlConst from 'consts/UrlConst';
import JoditEditor from 'jodit-react';
import React from 'react';
import { withAlert } from 'react-alert';
import Dropzone from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import BaseScreen from 'screens/BaseScreen';
import Utils from 'utils/Utils';
import styles from './styles.module.css';

export class AddMapScreen extends BaseScreen {
  constructor(props) {
    super(props);
    this.state = {
      process: false
    };
    this.data = {};
    this.type = 'map3';
  }

  validate = () => {
    const { title } = this.state;
    // Validate
    if (!title) {
      this.props.alert.error('Tiêu đề ko hợp lệ');
      return false;
    }
    if (!this.data.icon) {
      this.props.alert.error('Icon ko hợp lệ');
      return false;
    }
    if (!this.data.content) {
      this.props.alert.error('Nội dung ko hợp lệ');
      return false;
    }
    if (!this.state.file) {
      this.props.alert.error('File upload ko hợp lệ');
      return false;
    }
    return true;
  };

  onClickProcess = async () => {
    this.data = Utils.formatMcpeData(this.editor.value);
    this.editor.value = this.data?.content;
    this.setState({ process: true, title: this.data.title || this.state.title, file: null });
  };

  onClickReset = () => {
    this.data = {};
    this.setState({ process: false, file: null, title: '' });
    this.editor.value = '';
  };

  onClickSubmit = async () => {
    ModalLoading.show();
    if (!this.validate()) return;
    const { title } = this.state;
    try {
      const res = await Fetch.postForm(UrlConst.API.MCPE + `${this.type}/add`, { ...this.data, title, file: this.state.file }, { key: ApiConst.KEY.MCPE_KEY });
      if (res.status === 200) {
        this.props.alert.success('Thành công');
        this.onClickReset();
      } else if (res?.data?.code === 'ER_DUP_ENTRY') {
        this.props.alert.error('Lỗi đã tồn tại!');
      } else {
        this.props.alert.error('Có lỗi xảy ra ' + res?.data?.code);
      }
    } catch (err) {
      console.log('ERROR', err);
      this.props.alert.error('Có lỗi xảy ra, vui lòng thử lại!');
    }
    ModalLoading.hide();
  };

  renderFileUpload = () => {
    const { file } = this.state;
    return (
      <Dropzone
        multiple={false}
        onDrop={(acceptedFiles) => {
          this.setState({
            file: acceptedFiles[0]
          });
        }}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className={styles.dropzone}>
              <input {...getInputProps()} />
              <p>Kéo file vào ô này, hoặc bấm vào đây để chọn file</p>
              <ul>{file ? <li style={{ fontWeight: 'bold' }}>{file.name}</li> : ''}</ul>
            </div>
          </section>
        )}
      </Dropzone>
    );
  };

  renderDataProcess = () => {
    const { process, title } = this.state;
    if (!process) return false;
    return (
      <div>
        <div style={{ marginBottom: 20 }}>Thông tin</div>
        <table className={styles.table} cellSpacing={1}>
          <tbody>
            <tr className={styles.tr}>
              <td width={'20%'} className={styles.td}>
                Tên map
              </td>
              <td width={'80%'} className={styles.td}>
                {title}
              </td>
            </tr>
            <tr className={styles.tr}>
              <td width={'20%'} className={styles.td}>
                Icon
              </td>
              <td width={'80%'} className={styles.td}>
                <img src={this.data.icon} style={{ width: 50, height: 50 }} />
              </td>
            </tr>
            <tr className={styles.tr}>
              <td className={styles.td}>Map file</td>
              <td className={styles.td}>{this.renderFileUpload()}</td>
            </tr>
          </tbody>
        </table>
        <div className={'centerContent'}>
          <Button className={styles.button} onClick={this.onClickSubmit}>
            Xác nhận
          </Button>
        </div>
      </div>
    );
  };

  renderContent = () => {
    return (
      <div>
        <div>Copy nội dung từ trang mcpedl vào đây</div>
        <br />
        <JoditEditor ref={(ref) => (this.editor = ref)} config={{ readonly: false, maxHeight: 400 }} tabIndex={1} />
        <div className={'centerContent'}>
          {/* <Button className={styles.button} onClick={this.onClickReset}>
            Reset
          </Button> */}
          <Button className={styles.button} onClick={this.onClickProcess}>
            Xử lý
          </Button>
        </div>
        {this.renderDataProcess()}
      </div>
    );
  };
}

export default withAlert()(withRouter(AddMapScreen));

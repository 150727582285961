import Button from 'common/components/views/Button';
import React from 'react';
import { GrClose } from 'react-icons/gr';
import Modal from 'react-modal';

export default class ModalUserInfo extends React.Component {
  static instance = null;

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    ModalUserInfo.instance = this;
  }

  static show = () => {
    ModalUserInfo.instance?.setState({
      visible: true
    });
  };

  static hide = () => {
    ModalUserInfo.instance?.setState({
      visible: false
    });
  };

  render() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.visible}
        style={{
          overlay: { backgroundColor: 'rgba(0,0,0, 0.5)' },
          content: { maxWidth: 1132, maxHeight: 710, margin: 'auto' }
        }}>
        <div>
          <Button style={{ position: 'absolute', right: 20, top: 20 }} onClick={ModalUserInfo.hide}>
            <GrClose />
          </Button>
        </div>
      </Modal>
    );
  }
}

import Configs from 'utils/Configs';

const API = {
  dev: 'http://localhost:8802/api/',
  prod: 'http://xgitvn.com:8802/api/'
};

const API1 = {
  dev: 'http://sv.xgitvn.com/api-v2/coc/base/',
  prod: 'http://sv.xgitvn.com/api-v2/coc/base/'
};

const CDN = {
  dev: 'http://xgitvn.com:8802/cdn/',
  prod: 'http://xgitvn.com:8802/cdn/'
};

export default {
  API: API[Configs.mode],
  API1: API1[Configs.mode],
  CDN: CDN[Configs.mode]
};

export default {
  KEY: {
    MCPE_KEY: 'xH33tKjjBaYmIcDmriv4w22dvfASVeY1TalxlWSy2YmIk'
  },
  USER: {
    LOGIN: 'user/login',
    AUTO_LOGIN: 'user/auto-login'
  },
  LINK_BASE: {
    GET_LIST: 'link-base/get-list',
    GET_DETAIL: 'link-base/get',
    UPDATE_VIEW: 'link-base/view'
  },
  USER_BASE: {
    GET_LIST: 'user-base/get-list',
    GET_DETAIL: 'user-base/get',
    UPDATE_VIEW: 'user-base/view'
  }
};

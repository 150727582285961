import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './styles.module.css';

export default () => {
  return (
    <div className={styles.container}>
      <div className={styles.textTitle}></div>
      <div className={styles.infoContainer}>
        <Link to={'/privacy'}>Privacy</Link>
        <Link to={'/about'}>About</Link>
        <Link to={'/contact'}>Contact</Link>
      </div>
    </div>
  );
};

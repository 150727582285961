import Funcs from 'common/helpers/Funcs';
import React from 'react';
import { BiDownload, BiLike } from 'react-icons/bi';
import { GrView } from 'react-icons/gr';

export default ({ item, style, isShowFull }) => {
  const containerStyle = { display: 'flex', margin: isShowFull ? 5 : 0, ...style };
  const infoStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10, fontSize: 13 };
  return (
    <div style={containerStyle}>
      <div style={infoStyle}>
        <GrView />
        <span style={{ marginLeft: 5 }}>
          {Funcs.formatNumber(item.views || Funcs.random(100, 2000))}
          {isShowFull ? ' views' : ''}
        </span>
      </div>
      <div style={infoStyle}>
        <BiLike />
        <span style={{ marginLeft: 5 }}>
          {Funcs.formatNumber(item.liked || Funcs.random(100, 2000))} {isShowFull ? ' likes' : ''}
        </span>
      </div>
      <div style={infoStyle}>
        <BiDownload />
        <span style={{ marginLeft: 5 }}>
          {Funcs.formatNumber(item.liked || Funcs.random(100, 2000))}
          {isShowFull ? ' download' : ''}
        </span>
      </div>
    </div>
  );
};

import Fetch from 'common/helpers/Fetch';
import Funcs from 'common/helpers/Funcs';
import ApiConst from 'consts/ApiConst';
import DataConst from 'consts/DataConst';
import Configs from './Configs';

export default {
  data: [],
  currentHall: [],
  async init() {
    Funcs.log('Utils init');
    Configs.token = localStorage.getItem(DataConst.USER_TOKEN);

    // Auto login
    Configs.user = {};
    if (Configs.token) {
      const res = await Fetch.post(ApiConst.USER.AUTO_LOGIN, {}, true);
      Configs.user = res.data;
    }
  },

  release() {}
};

import Fetch from 'common/helpers/Fetch';
import ApiConst from 'consts/ApiConst';
import UrlConst from 'consts/UrlConst';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { DetailBaseLinkScreen } from '../DetailBaseLinkScreen';

export class DetailBaseLayoutScreen extends DetailBaseLinkScreen {
  getData = async () => {
    Fetch.post(ApiConst.USER_BASE.UPDATE_VIEW, { id: this.id });

    if (this.state.data?.id) return;
    const res = await Fetch.get(ApiConst.USER_BASE.GET_DETAIL, { id: this.id });
    if (res.status === 200) {
      this.setState({ data: res.data, hall: res.data?.hall, title: res.data?.title });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.match?.params?.id != this.props.match?.params?.id && nextProps.match?.params?.id != this.id) {
      this.id = nextProps.match?.params?.id || 10;
      this.getData();
    }
    return this.state != nextState;
  }

  renderImage = () => {
    let { data = {}, title = '' } = this.state;
    return <img alt={title} src={UrlConst.CDN + 'user_base/default/' + data.image} style={{ width: '100%', height: '100%' }} />;
  };
}

export default withRouter(DetailBaseLayoutScreen);

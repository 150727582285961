import lodash from 'lodash';
import { decode } from 'html-entities';
import md5 from 'common/helpers/Md5';

export default {
  format: (textFormat, value = []) => {
    var formatted = textFormat;
    for (var i = 0; i < value.length; i++) {
      formatted = formatted.replace('%s', value[i]);
    }
    return formatted;
  },
  removeLineBreak(text) {
    const result = text.replace(/[\r\n]+/gm, '');
    return result.trim();
  },
  removeDoubleSpace(str) {
    return str.replace(/\s{2,}/g, ' ');
  },
  replaceUnicode: (str) => {
    if (str === null || str === undefined) return str;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    return str;
  },

  replaceSignUnicode: (str) => {
    if (str === null || str === undefined) return str;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã/g, 'a');
    str = str.replace(/ầ|ấ|ậ|ẩ|ẫ/g, 'â');
    str = str.replace(/ằ|ắ|ặ|ẳ|ẵ/g, 'ă');
    str = str.replace(/è|é|ẹ|ẻ|ẽ/g, 'e');
    str = str.replace(/ề|ế|ệ|ể|ễ/g, 'ê');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ/g, 'o');
    str = str.replace(/ồ|ố|ộ|ổ|ỗ/g, 'ô');
    str = str.replace(/ờ|ớ|ợ|ở|ỡ/g, 'ơ');
    str = str.replace(/ù|ú|ụ|ủ|ũ/g, 'u');
    str = str.replace(/ừ|ứ|ự|ử|ữ/g, 'ư');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    return str;
  },

  replaceAll: (str, from, to) => {
    return str.replace(new RegExp(from, 'g'), to);
  },

  toCamelCase: (str) => {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  },

  decodeCamelCase: (str) => {
    return str
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/([A-Z][a-z])/g, ' $1')
      .trim();
  },

  capitalize: (str) => {
    return lodash.capitalize(str);
  },

  capitalizeAll: (str) => {
    return lodash.startCase(lodash.toLower(str));
  },

  getFileNameFromPath: (path = '') => {
    try {
      return path.split('/').reverse()[0];
    } catch (err) {
      return path;
    }
  },

  md5: (str) => md5(str),

  decodeEntities: (str) => decode(str),

  normalPath: (str) => {
    return str.replace(new RegExp(' ', 'g'), '-').toLowerCase();
  }
};

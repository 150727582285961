import React from 'react';

export default ({ disabled, children, className, onClick, style = {} }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={!className ? { fontSize: 16, backgroundColor: 'transparent', textAlign: 'center', borderColor: 'transparent', padding: 10, ...style } : {}}
      className={className}>
      {children}
    </button>
  );
};

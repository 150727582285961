import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Colors from 'assets/Colors';

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary
    }
  }
});

const options = {
  position: positions.TOP_CENTER,
  timeout: 2000,
  transition: transitions.FADE
};

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

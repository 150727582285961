import Button from 'common/components/views/Button';
import Fetch from 'common/helpers/Fetch';
import ApiConst from 'consts/ApiConst';
import UrlConst from 'consts/UrlConst';
import JoditEditor from 'jodit-react';
import React from 'react';
import { withAlert } from 'react-alert';
import { GrClose } from 'react-icons/gr';
import Modal from 'react-modal';
import Utils from 'utils/Utils';
import styles from './styles.module.css';

export class ModalEditMcpeDetail extends React.Component {
  static instance = null;

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    ModalEditMcpeDetail.instance = this;
  }

  static show = async (data, type, callback) => {
    ModalEditMcpeDetail.instance?.setState({ visible: true, type, data, callback });
  };

  static hide = () => {
    ModalEditMcpeDetail.instance?.setState({
      visible: false
    });
  };

  onClickFormat = () => {
    const { data } = this.state;
    const process = Utils.formatMcpeData(this.editor.value || data?.content);
    this.setState({
      data: { ...data, content: process?.content }
    });
  };

  onClickSave = async () => {
    const { data, callback, type } = this.state;
    data.content = this.editor.value;
    data.title = this.inputTitle.value;
    data.icon = this.inputIcon.value;
    try {
      const res = await Fetch.post(UrlConst.API.MCPE + `${type}/edit`, data, { key: ApiConst.KEY.MCPE_KEY });
      if (res.status === 200) {
        this.props.alert.success(`Lưu thành công ${data.title}`);
        ModalEditMcpeDetail.hide();
        if (callback) callback();
      } else this.props.alert.error('Có lỗi xảy ra ' + res?.data?.error?.code);
    } catch (err) {
      console.log('ERROR', err);
      this.props.alert.error('Có lỗi xảy ra, vui lòng thử lại!');
    }
  };

  render() {
    const { data } = this.state;
    return (
      <Modal
        onRequestClose={ModalEditMcpeDetail.hide}
        ariaHideApp={false}
        isOpen={this.state.visible}
        style={{
          overlay: { backgroundColor: 'rgba(0,0,0, 0.5)' },
          content: { maxWidth: 1132, maxHeight: 710, margin: 'auto', overflow: 'hidden', padding: 0 }
        }}>
        <div className={styles.header}>
          <div />
          <div>{data?.title}</div>
          <Button onClick={ModalEditMcpeDetail.hide}>
            <GrClose />
          </Button>
        </div>
        <div className={styles.contentContainer}>
          <div>
            <table width={'100%'} style={{ marginBottom: 10 }}>
              <tbody>
                <tr>
                  <td width={'50%'}>
                    <div className={styles.title}>Title</div>
                    <input ref={(ref) => (this.inputTitle = ref)} defaultValue={data?.title} />
                  </td>
                  <td width={'50%'}>
                    <div className={styles.title}>Icon</div>
                    <input ref={(ref) => (this.inputIcon = ref)} defaultValue={data?.icon} />
                    {/* <img className={styles.icon} src={UrlConst.CDN.MCPE + 'map3/icons/small/' + data?.icon} /> */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <JoditEditor value={data?.content} ref={(ref) => (this.editor = ref)} config={{ readonly: false, maxHeight: 480 }} tabIndex={1} />
          <div className={'centerContent'}>
            <Button className={styles.button} onClick={this.onClickFormat}>
              Format
            </Button>
            <Button className={styles.button} onClick={this.onClickSave}>
              Lưu
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withAlert()(ModalEditMcpeDetail);

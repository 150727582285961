import { Box, ButtonBase, Card, Grid, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import Colors from 'assets/Colors';
import Fetch from 'common/helpers/Fetch';
import MapInfo from 'components/views/MapInfo';
import UrlConst from 'consts/UrlConst';
import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { withRouter } from 'react-router-dom';
import BaseScreen from 'screens/BaseScreen';
import FilterView from 'screens/BaseScreen/FilterView';
import Utils from 'utils/Utils';
import styles from './styles.module.css';
import Strings from 'common/helpers/Strings';
import ApiConst from 'consts/ApiConst';
import AppConst from 'consts/AppConst';

export const TypographyTitle = withStyles(() => createStyles({ root: { fontWeight: '700', fontSize: 14, color: '#25282B' } }))(Typography);
const TypographyContent = withStyles(() => createStyles({ root: { fontSize: 14, color: '#52575C' } }))(Typography);
const ButtonPage = withStyles(() =>
  createStyles({ root: { borderWidth: 1, borderStyle: 'solid', margin: 5, borderRadius: 6, borderColor: '#E8E8E8', padding: 10 } })
)(ButtonBase);

export class ListBaseLinkScreen extends BaseScreen {
  constructor(props) {
    super(props);
    this.title = props.location?.data?.title;
    const { params } = this.props.match;
    this.state = { page: params?.page || 1, hasNext: true, data: Utils.data, hall: params?.hall || 4 };
    this.type = 0; // 0 Link hall, 1 Link builder , 2 user base, 3 layout
    this.path = 'base-link-town-hall';
    this.hallList = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  }

  componentDidMount() {
    this.getData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.match?.params?.hall != this.props.match?.params?.hall && nextProps.match?.params?.hall != this.hall) {
      this.hall = nextProps.match?.params?.hall || 4;
      this.setState({ hall: this.hall }, this.getData);
    }
    return this.state != nextState;
  }

  renderIcon = (e) => {
    let icon = e.icon;
    if (icon.indexOf('http') < 0) icon = this.cdnUrl + 'icons/small/' + e.icon;
    return <img className={styles.icon} key={e.id} src={icon} />;
  };

  getData = async () => {
    const itemsPerPage = 20;
    this.setState({
      title: 'Base hall ' + this.state.hall,
      breadcrumb: [{ to: this.props.location.pathname, title: 'Base hall ' + this.state.hall }]
    });
    const res = await Fetch.get(ApiConst.LINK_BASE.GET_LIST, { page: this.state.page, hall: this.state.hall, itemsPerPage });
    if (res.status === 200) {
      Utils.data = res.data.data?.map((e) => {
        const tags = [...AppConst.TAGS, 'TH' + e.hall];
        return { ...e, tags };
      });
      this.setState({ data: Utils.data, hasNext: Utils.data.length >= itemsPerPage });
    }
  };

  onClickItem = (item) => () => {
    this.props.history.push((this.type === 2 ? '/base-layout/' : '/base-link/') + item.id + '/' + Strings.normalPath(item.title), { ...item, type: this.type });
  };

  onClickFilterItem = (item) => () => {
    this.props.history.push(`/${this.path}/` + item);
    // this.getData(item);
  };

  onClickBackPage = () => {
    let { page } = this.state;
    if (page <= 1) return;
    if (!page) page = 1;
    page--;
    this.setState({ page }, this.getData);
  };

  onClickNextPage = () => {
    let { page, hall, hasNext } = this.state;
    if (!hasNext) return;
    if (!page) page = 1;
    page++;
    this.setState({ page }, this.getData);
    this.props.history.push(`/${this.path}/` + hall + '/page/' + page);
  };

  renderItem = (item, index) => {
    const image = UrlConst.CDN + (this.type === 2 ? 'user_base/small/' : 'link_base/images/') + item.image;
    return (
      <Grid key={index} item sm={6} xs={12} style={{ alignItems: 'center' }}>
        <Card style={{ display: 'flex', padding: 10, alignItems: 'flex-start' }}>
          <ButtonBase onClick={this.onClickItem(item)}>
            <img style={{ width: 200, height: 150, objectFit: 'cover' }} src={image} />
          </ButtonBase>
          <div style={{ paddingLeft: 15, width: '100%', height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <ButtonBase onClick={this.onClickItem(item)} style={{ fontWeight: 'bold', textAlign: 'left', fontSize: 16, color: Colors.primary }}>
                {Strings.capitalize(item.title)}
              </ButtonBase>
              <MapInfo item={item} className={styles.itemInfoContainer}></MapInfo>
            </div>
            <div className={styles.flex} style={{ flexWrap: 'wrap' }}>
              {item?.tags?.map((e, i) => (
                <div key={i} style={{ padding: 5, backgroundColor: Colors.primary, color: 'white', margin: 5 }}>
                  {e}
                </div>
              ))}
            </div>
          </div>
        </Card>
      </Grid>
    );
  };

  renderContent = () => {
    const { page, hasNext, data = [] } = this.state;
    return (
      <div>
        <FilterView hall={this.hallList} onClickFilterItem={this.onClickFilterItem} basePath={`/${this.path}/`} isBuilderHall={this.type === 1} />
        <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
          {data.map(this.renderItem)}
        </Grid>
        <Box style={{ margin: 10 }}>
          <ButtonPage onClick={this.onClickBackPage} disabled={page <= 1}>
            <IoIosArrowBack color={Colors.primary} size={20} />
          </ButtonPage>
          <TypographyContent style={{ margin: 10 }} component={'span'}>
            {page}
          </TypographyContent>
          <ButtonPage onClick={this.onClickNextPage} disabled={!hasNext}>
            <IoIosArrowBack style={{ transform: 'scaleX(-1)' }} color={Colors.primary} size={20} />
          </ButtonPage>
        </Box>
      </div>
    );
  };
}

export default withRouter(ListBaseLinkScreen);

import { ButtonBase, Link } from '@material-ui/core';
import Colors from 'assets/Colors';
import Fetch from 'common/helpers/Fetch';
import Strings from 'common/helpers/Strings';
import MapInfo from 'components/views/MapInfo';
import TagInfo from 'components/views/TagInfo';
import ApiConst from 'consts/ApiConst';
import AppConst from 'consts/AppConst';
import UrlConst from 'consts/UrlConst';
import React from 'react';
import { withRouter } from 'react-router-dom';
import BaseScreen from 'screens/BaseScreen';

export class DetailBaseLinkScreen extends BaseScreen {
  constructor(props) {
    super(props);
    const { state = {} } = props.location;
    this.state = { data: state, hall: state?.hall, title: Strings.capitalize(state.title) };
    this.id = this.props.match?.params?.id;
  }

  async componentDidMount() {
    this.getData();
  }

  getData = async () => {
    Fetch.post(ApiConst.LINK_BASE.UPDATE_VIEW, { id: this.id });

    if (this.state.data?.id) return;
    const res = await Fetch.get(ApiConst.LINK_BASE.GET_DETAIL, { id: this.id });
    if (res.status === 200) {
      this.setState({ data: res.data, hall: res.data?.hall, title: res.data?.title });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.match?.params?.id != this.props.match?.params?.id && nextProps.match?.params?.id != this.id) {
      this.id = nextProps.match?.params?.id || 10;
      this.getData();
    }
    return this.state != nextState;
  }

  renderImage = () => {
    let { data = {}, title = '' } = this.state;
    return (
      <div>
        <img alt={title} src={UrlConst.CDN + 'link_base/images/' + data.image} style={{ width: '100%', height: '100%' }} />
        <div className={'centerContent'}>
          <Link
            target={'_blank'}
            href={'https://link.clashofclans.com/en?action=' + data.link}
            style={{ padding: 10, backgroundColor: Colors.primary, color: 'white', fontWeight: 'bold', margin: 20, borderRadius: 10 }}>
            {'Copy layout'}
          </Link>
        </div>
      </div>
    );
  };

  renderContent = () => {
    let { data = {} } = this.state;
    const { tags = AppConst.TAGS } = data;
    return (
      <div style={{ padding: 10 }}>
        <div className={'centerContent'} style={{ flexDirection: 'column' }}>
          <TagInfo tags={tags} />
          <MapInfo isShowFull item={data} />
        </div>
        {this.renderImage()}
      </div>
    );
  };
}

export default withRouter(DetailBaseLinkScreen);

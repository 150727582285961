import { ButtonBase, Card } from '@material-ui/core';
import Fetch from 'common/helpers/Fetch';
import Strings from 'common/helpers/Strings';
import UrlConst from 'consts/UrlConst';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default () => {
  const [data, setData] = useState([]);
  const getData = async () => {
    const url = UrlConst.API + 'link-base/get-list';
    const res = await Fetch.get(url, { random: 1, itemsPerPage: 5 });
    if (res.status === 200) {
      setData(res.data.data);
    }
  };
  const history = useHistory();

  useEffect(() => {
    getData();
  }, []);

  const onClick = (item) => () => {
    history.push('/base-link/' + item.id + '/' + Strings.normalPath(item.title), item);
  };

  return (
    <div className={'shadow'} style={{ marginTop: 10, padding: 10 }}>
      <h3>Recommended for you</h3>
      {data.map((e, i) => {
        return (
          <Card key={i} style={{ margin: 10, padding: 10, justifyContent: 'center' }}>
            <ButtonBase style={{ width: '90%' }} onClick={onClick(e)}>
              <img style={{ width: '100%', height: 150, objectFit: 'cover' }} src={UrlConst.CDN + 'link_base/images/' + e.image} />
            </ButtonBase>
            <ButtonBase onClick={onClick}>
              <a>{Strings.capitalize(e.title)}</a>
            </ButtonBase>
          </Card>
        );
      })}
    </div>
  );
};

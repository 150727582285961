import RouteConst from 'consts/RouteConst';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './styles.module.css';
import { BiShield } from 'react-icons/bi';

const menu = [
  {
    path: 'base-link-town-hall',
    title: 'Base Links - Town Hall'
  },
  {
    path: 'base-link-builder-hall',
    title: 'Base Links - Builder Hall'
  },
  {
    title: 'COC Fans bases layout',
    path: 'base-layout-town-hall'
  }
  // {
  //   path: 'upload-base',
  //   title: 'Upload your base'
  // }
];

export default () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { pathname } = useLocation();
  useEffect(() => {
    for (let i = 0; i < RouteConst.length; i += 1) {
      if (pathname === '/' + RouteConst[i].path) {
        setActiveIndex(i);
        break;
      }
    }
  }, []);
  return (
    <div className={styles.container}>
      <Link to="/" className={styles.link}>
        <div className={styles.textSlogan}>Cocbase Layout</div>
      </Link>
      <div className={styles.containerMenu}>
        {menu.map((e, i) => {
          if (e.hideInDrawer) return null;
          if (e.icon) {
            return (
              <div className={styles.li1} key={i.toString()}>
                {e.icon} {e.title}
              </div>
            );
          }
          return (
            <div style={{ display: 'flex', alignItems: 'center', padding: 6 }} key={i.toString()}>
              <BiShield />
              <Link
                className={activeIndex === i ? styles.linkActive : styles.link}
                to={{ pathname: '/' + e.path, data: e }}
                onClick={() => {
                  setActiveIndex(i);
                }}>
                {e.title}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import { Breadcrumbs, Grid } from '@material-ui/core';
import RecommendForYou from 'components/views/RecommendForYou';
import React, { Component } from 'react';
import { GrHome } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import './BaseScreen.scss';

export default class BaseScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { hall: 1, breadcrumb: [] };
    this.title = '';
  }

  renderContent = () => {
    return null;
  };

  render() {
    const { breadcrumb = [], hall, title } = this.state;
    document.title = (title ? title + ' - ' : '') + 'CoC Bases Links, Clash of Clans Maps Layouts Links | CocBases.Top';
    return (
      <div style={{}}>
        <div className={'shadow'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 10 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={`/images/hall/Town_Hall${hall}.png`} style={{ width: 40, height: 40, margin: 10 }} />
            <h3 style={{ fontWeight: 'bold' }}>{title}</h3>
          </div>
          <Breadcrumbs aria-label="breadcrumb">
            <GrHome />{' '}
            {breadcrumb.map((e, i) => {
              return (
                <Link key={i} underline="hover" color="inherit" href={e.to}>
                  {e.title}
                </Link>
              );
            })}
          </Breadcrumbs>
        </div>
        <Grid container>
          <Grid sm={9} sx={12} item>
            <div className={'shadow'} style={{ margin: 10 }}>
              {this.renderContent()}
            </div>
          </Grid>
          <Grid sm={3} sx={12} item>
            <RecommendForYou />
          </Grid>
        </Grid>
      </div>
    );
  }
}

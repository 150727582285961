import Button from 'common/components/views/Button';
import React from 'react';
import Modal from 'react-modal';
import styles from './styles.module.css';

export default class ModalConfirm extends React.Component {
  static instance = null;

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    ModalConfirm.instance = this;
  }

  static show = (data) => {
    ModalConfirm.instance?.setState({
      visible: true,
      data
    });
  };

  static hide = () => {
    ModalConfirm.instance?.setState({
      visible: false
    });
  };

  render() {
    const { data } = this.state;
    return (
      <Modal
        onRequestClose={ModalConfirm.hide}
        ariaHideApp={false}
        isOpen={this.state.visible}
        style={{
          overlay: { backgroundColor: 'rgba(0,0,0, 0.5)' },
          content: { maxWidth: 400, maxHeight: 180, margin: 'auto', padding: 0 }
        }}>
        <div className={styles.header}>
          <div className={styles.title}>{data?.title}</div>
        </div>
        <div className={styles.contentContainer}>{data?.content}</div>
        <div className={styles.containerBottom}>
          <Button
            className={styles.buttonBottom}
            onClick={() => {
              ModalConfirm.hide();
              if (data?.onClickOk) data?.onClickOk();
            }}>
            Đồng ý
          </Button>
          <Button className={styles.buttonBottom} onClick={ModalConfirm.hide}>
            Hủy
          </Button>
        </div>
      </Modal>
    );
  }
}

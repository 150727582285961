import ModalLoading from 'common/components/modals/ModalLoading';
import ModalConfirm from 'components/modals/ModalConfirm';
import ModalEditMcpeDetail from 'components/modals/ModalEditMcpeDetail';
import ModalMcpeDetail from 'components/modals/ModalMcpeDetail';
import ModalUserInfo from 'components/modals/ModalUserInfo';
import DrawerMenuView from 'components/views/DrawerMenuView';
import HeaderView from 'components/views/HeaderView';
import RouteConst from 'consts/RouteConst';
import React, { useEffect, useState } from 'react';
import { Ring } from 'react-awesome-spinners';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Utils from 'utils/Utils';
import './App.css';
export default () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function init() {
      setLoading(false);
    }
    init();
    return () => {
      Utils.release();
    };
  }, []);

  // Loading
  if (loading) {
    return (
      <div className="rootView centerContent">
        <Ring color={'gray'} />
      </div>
    );
  }

  // Succss
  return (
    <Router>
      <div className="rootView">
        <DrawerMenuView />
        <div className="rootContent">
          <HeaderView />
          <Switch>
            {RouteConst.map((e, i) => {
              return (
                <Route exact key={i.toString()} path={`/${e.path}`}>
                  <e.component />
                </Route>
              );
            })}
          </Switch>
        </div>
        <ModalUserInfo />
        <ModalMcpeDetail />
        <ModalEditMcpeDetail />
        <ModalConfirm />
        <ModalLoading />
      </div>
    </Router>
  );
};

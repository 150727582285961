import AboutScreen from 'screens/AboutScreen';
import ContactScreen from 'screens/ContactScreen';
import AddMapScreen from 'screens/map/AddMapScreen';
import DetailBaseLayoutScreen from 'screens/map/DetailBaseLayoutScreen';
import DetailBaseLinkScreen from 'screens/map/DetailBaseLinkScreen';
import ListBaseLayoutScreen from 'screens/map/ListBaseLayoutScreen';
import ListBaseLinkScreen from 'screens/map/ListBaseLinkScreen';
import ListBuilderScreen from 'screens/map/ListBuilderScreen';
import PrivaceScreen from 'screens/PrivaceScreen';

export default [
  {
    path: '',
    component: ListBaseLinkScreen
  },
  {
    path: 'base-link-town-hall/:hall?',
    component: ListBaseLinkScreen
  },
  {
    path: 'base-link-town-hall/:hall?/page/:page?',
    component: ListBaseLinkScreen
  },
  {
    path: 'base-link/:id/:title?',
    component: DetailBaseLinkScreen
  },
  {
    path: 'base-link-builder-hall/:hall?',
    component: ListBuilderScreen
  },
  {
    path: 'base-link-builder-hall/:hall?/page/:page?',
    component: ListBuilderScreen
  },
  {
    path: 'base-layout-town-hall/:hall?',
    component: ListBaseLayoutScreen
  },
  {
    path: 'base-layout-town-hall/:hall?/page/:page?',
    component: ListBaseLayoutScreen
  },
  {
    path: 'base-layout/:id/:title?',
    component: DetailBaseLayoutScreen
  },
  {
    path: 'base-layout',
    component: AddMapScreen
  },
  {
    path: 'upload-base',
    component: AddMapScreen
  },
  {
    path: 'privacy',
    component: PrivaceScreen
  },
  {
    path: 'about',
    component: AboutScreen
  },
  {
    path: 'contact',
    component: ContactScreen
  }
];

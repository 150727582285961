import Colors from 'assets/Colors';
import React from 'react';

export default ({ tags }) => {
  return (
    <div style={{ display: 'flex' }}>
      {tags.map((e, i) => (
        <div key={i} style={{ padding: 5, backgroundColor: Colors.primary, color: 'white', margin: 5 }}>
          {e}
        </div>
      ))}
    </div>
  );
};
